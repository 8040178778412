.kozoom-video-player .wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.kozoom-video-player video {
  outline: none;
}

.vjs-logo img {
  width: 160px;
  height: 49px;
}

.video-js {
  font-size: 12px;
}

.video-js .vjs-control-bar,
.video-js .vjs-volume-vertical {
  background-color: rgba(2, 2, 60, 0.7);
}

.video-js .vjs-big-play-button {
  width: 2em;
  height: 2em;
  top: calc(50% - 1em);
  left: calc(50% - 1em);
  border: 2px solid white;
  border-radius: 1em;
}

.vjs-youtube-mobile:not(.vjs-has-started) .vjs-big-play-button {
  display: block;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vjs-button.score-board-botton {
  width: min-content !important;
  margin-right: 1em !important;
}

.score-button-img-icon {
  width: 70px;
}

/* vimeo */
.vimeoFrame {
  padding-bottom: 0 !important;
  position: initial !important;
}

.vjs-control.vjs-button {
  cursor: pointer;
}

.vjs-control.vjs-button:disabled {
  opacity: 0.4;
}

.vjs-airplay-button .vjs-icon-placeholder {
  background: url(./assets/ic_airplay_white_24px.svg) center center no-repeat;
  background-size: contain;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.vjs-airplay-button .vjs-icon-placeholder:hover {
  cursor: pointer;
  background-image: url(./assets/ic_airplay_white_24px.svg);
}

.vjs-live-display {
  color: #e00e2f;
  font-weight: bold;
}

body.vjs-full-window .MuiTabs-root,
body.vjs-full-window header {
  position: static !important;
}