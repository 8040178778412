.icon {
  width: 20px;
  height: 20px;
  filter: invert(1);
  margin-right: 5px;
  vertical-align: bottom;
}

.icon.grey {
  filter: invert(0.4);
}
