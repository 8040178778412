.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day--selected {
  border-radius: 50%;
  background-color: #e00e2f;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: transparent;
  color: inherit;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: transparent;
}

.react-datepicker__day--selected:hover {
  border-radius: 50%;
  background-color: #e00e2f;
}

.react-datepicker__current-month {
  padding-bottom: 16px;
  color: #444444;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.react-datepicker__month {
  padding-bottom: 16px;
}

.react-datepicker__week {
  margin-bottom: 12px;
}

.react-datepicker__day {
  position: relative;
}

.react-datepicker__day .has-event:after {
  position: absolute;
  bottom: -10px;
  left: 10px;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #E00D2E;
}

.react-datepicker__day .has-event.old-event:after {
  background-color: gray;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-between;
}