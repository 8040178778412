.slick-next,
.slick-prev {
  width: 47px;
  height: 47px;
}

.slick-arrow {
  position: absolute !important;
  background-color: #4F4E79 !important;
}

.slick-arrow .carousel-arrow-icon {
  color: #E00D2E;
}

.slick-arrow:hover .carousel-arrow-icon {
  color: white;
  transition: all 0.2s ease-in;
}

.slick-next:before, .slick-prev:before {
  content: '';
}

.slick-prev {
  left: -15px;
  z-index: 10;
}

.slick-next {
  right: -15px;
  z-index: 10;
}
